import {Constants} from "./constants";
import {HttpService} from "./http";
import {IKyuCandidate, IKyuChairMember, IKyuExam} from "./models/KyuExam";
import {AuthService} from "./auth";


const GET_KYU_EXAMS = `${Constants.JAMA_SERVER_API_V2}/admin/:org/kyu`;
const GET_KYU_EXAM = `${Constants.JAMA_SERVER_API_V2}/admin/:org/kyu/`;
const GET_KYU_CANDIDATES = `${Constants.JAMA_SERVER_API_V2}/admin/:org/kyu/candidates/`;

const PUT_KYU_CANDIDATE = `${Constants.JAMA_SERVER_API_V2}/admin/:org/kyu/candidates`;
const PUT_KYU_CANDIDATES_BATCH = `${Constants.JAMA_SERVER_API_V2}/admin/:org/kyu/:id/candidatesbatch`;

const POST_KYU_EXAM = `${Constants.JAMA_SERVER_API_V2}/admin/:org/kyu`;
const PUT_KYU_EXAM = `${Constants.JAMA_SERVER_API_V2}/admin/:org/kyu`;
const PUT_KYU_EXAM_UPGRADE = `${Constants.JAMA_SERVER_API_V2}/admin/:org/kyu/:id/upgrade`;
const PUT_KYU_EXAM_APPROVE = `${Constants.JAMA_SERVER_API_V2}/admin/:org/kyu/:id/approve`;
const DELETE_KYU_EXAM_DELETE = `${Constants.JAMA_SERVER_API_V2}/admin/:org/kyu/:id/delete`;
const GET_KYU_EXAM_CHAIR_MEMBERS = `${Constants.JAMA_SERVER_API_V2}/admin/:org/kyu/chair/search`;
const GET_KYU_EXAM_CHAIR_MEMBER = `${Constants.JAMA_SERVER_API_V2}/admin/:org/kyu/chair/get`;



/**
 * Represents the parameters for updating a Kyu exam.
 */
interface IKyuExamUpdateParams {
    vorsitzer?: number;
    beisitzer1?: number;
    beisitzer2?: number;
}

/**
 * Represents a request object for creating an instance of IKyu.
 */
export interface IHandleAdminKyuCreateReq {
    name: string;
    date: string;
    examChairMember: IKyuExamUpdateParams;
}

/**
 * Interface for updating Kyu exams.
 */
interface IHandleAdminKyuUpdateReq {
    id: number;
    name: string;
    date: string;
    examChairMember: IKyuExamUpdateParams;
}

/**
 * Interface representing a request to get a list of chair members in IKyuListChairMembersReq class.
 */
export interface IKyuListChairMembersReq {
    date: string;
    search: string | null;
}


/**
 * A class that provides methods for interacting with Kyu exams and candidates.
 */
export class KyuService {
    /**
     * Retrieves the kyu exams for a given organization.
     *
     * @param org - The organization number.
     *
     * @returns A Promise that resolves to an array of IKyuExam objects.
     */
    public static async exams(org: number): Promise<IKyuExam[]> {
        await AuthService.refreshJwt();
        const res = await HttpService.get<IKyuExam[]>(`${GET_KYU_EXAMS}`.replace('/:org/', `/${org}/`), true, false);
        return res || [];
    }

    public static async get(org: number, id: number): Promise<IKyuExam> {
        await AuthService.refreshJwt();
        const res = await HttpService.get<IKyuExam>(`${GET_KYU_EXAM}${id}`.replace('/:org/', `/${org}/`), true);
        return res || []
    }

    /**
     * Retrieves the kyu exam candidates for the specified ID.
     *
     * @param {number} org - The ID of the organization.
     * @param {number} id - The ID of the kyu exam.
     * @return {Promise<IKyuCandidate[]>} A promise that resolves with an array of kyu candidates, or an empty array if none are found.
     */
    public static async candidates(org: number, id: number): Promise<IKyuCandidate[]> {
        await AuthService.refreshJwt();
        const res = await HttpService.get<IKyuCandidate[]>(`${GET_KYU_CANDIDATES}${id}`.replace('/:org/', `/${org}/`), true)
        return res || [];
    }

    /**
     * Updates a Kyu exam candidate.
     *
     * @param {number} org - The ID of the organization.
     * @param {number} examId - The ID of the exam.
     * @param {number} memberId - The ID of the member.
     * @param {boolean} remove - Indicates whether to remove the candidate.
     * @return {Promise<boolean>} - A Promise that resolves to a boolean indicating if the candidate was successfully updated or not.
     */
    public static async updateCandidate(org: number, examId: number, memberId: number, remove: boolean): Promise<boolean> {
        await AuthService.refreshJwt();
        const res = await HttpService.put2<{
            id: number,
            mgId: number,
            remove: boolean
        }, boolean>(PUT_KYU_CANDIDATE.replace('/:org/', `/${org}/`), {
            id: examId,
            mgId: memberId,
            remove,
        });
        return res || false;
    }

    /**
     * Creates a new Kyu exam.
     *
     * @param {number} org - The organization ID.
     * @param {IHandleAdminKyuCreateReq} data - The data for creating the Kyu exam.
     * @return {Promise<boolean>} - A promise that resolves to true if the Kyu exam is created successfully, otherwise false.
     */
    public static async create(org: number, data: IHandleAdminKyuCreateReq) {
        await AuthService.refreshJwt();
        return await HttpService.post2<IHandleAdminKyuCreateReq, number>(POST_KYU_EXAM.replace('/:org/', `/${org}/`), data);
    }

    /**
     * Updates the specified data using the POST_KYU_EXAM endpoint.
     *
     * @param {number} org - The organization number.
     * @param {IHandleAdminKyuUpdateReq} data - The data to be updated.
     *
     * @return {Promise<boolean>} - A Promise that resolves to a boolean indicating the success of the update operation.
     */
    public static async update(org: number, data: IHandleAdminKyuUpdateReq) {
        await AuthService.refreshJwt();
        await HttpService.put2<IHandleAdminKyuCreateReq, boolean>(PUT_KYU_EXAM.replace('/:org/', `/${org}/`), data);
        return data.id
    }

    /**
     * Retrieves a list of chair members for a Kyu exam.
     *
     * @param {number} org - The organization ID.
     * @param {IKyuListChairMembersReq} data - The request data.
     * @property {string} data.orgId - The organization ID.
     * @property {string} data.date - The exam date.
     * @property {string} data.search - The search value.
     *
     * @returns {Promise<IKyuCandidate[]>} A promise that resolves with an array of Kyu candidates.
     */
    public static async listChairMembers(org: number, data: IKyuListChairMembersReq) {
        await AuthService.refreshJwt();
        return await HttpService.get<IKyuChairMember[]>(
            `${GET_KYU_EXAM_CHAIR_MEMBERS.replace('/:org/', `/${org}/`)}/${data.date}${(data.search?.length || 0) > 0 ? `/${data.search}` : ''}`);
    }

    public static async getChairMember(orgId: number, date: string,  id: number) {
        await AuthService.refreshJwt();
        return await HttpService.get<IKyuChairMember>(
            `${GET_KYU_EXAM_CHAIR_MEMBER.replace('/:org/', `/${orgId}/`)}/${date}/${id}`);
    }


    public static async upgrade(org: number, id: number) {
        await AuthService.refreshJwt();
        return await HttpService.put<{ }>(
            PUT_KYU_EXAM_UPGRADE.replace('/:org/', `/${org}/`).replace('/:id/', `/${id}/`),{}
        )
    }

    public static async approve(org: number, id: number) {
        await AuthService.refreshJwt();
        return await HttpService.put<{}>(
            PUT_KYU_EXAM_APPROVE.replace('/:org/', `/${org}/`).replace('/:id/', `/${id}/`), {}
        )
    }

    public static async delete(org: number, id: number) {
        await AuthService.refreshJwt();
        return await HttpService.delete<{}>(
            DELETE_KYU_EXAM_DELETE.replace('/:org/', `/${org}/`).replace('/:id/', `/${id}/`)
        )
    }

    /**
     * Batch updates Kyu exam candidates.
     *
     * @param {number} org - The ID of the organization.
     * @param {number} examId - The ID of the exam.
     * @param {Array<{mgId: number, assignedMgId: number | undefined}>} candidates - An array of candidate updates.
     * @return {Promise<boolean>} - A Promise that resolves to a boolean indicating if the candidates were successfully updated or not.
     */


    public static async batchUpdateCandidates(
        org: number,
        id: number,
        assignedMgIds: Array<number | undefined>
    ): Promise<boolean> {
        await AuthService.refreshJwt();
        const res = await HttpService.put2<{
            candidatesAssigned: Array<number | undefined>
        }, boolean>(PUT_KYU_CANDIDATES_BATCH.replace('/:org/', `/${org}/`).replace('/:id/', `/${id}/`), {
            candidatesAssigned: assignedMgIds,
        });
        return res || false;
    }
}