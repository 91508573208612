import React, {useEffect, useMemo, useState} from 'react';
import {IPageLinkProps} from "../../page.props";
import {MemberService} from "../../../api/member";
import {useParamId, useParamRole} from "../../useParam";
import {IMember, ITraining} from "../../../api/models/MemberModel";
import {Button, DateInput, DTS, FD, FDX, Form, Item, Select} from "../../form";
import {Row} from "../../form/row";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {toaster} from "../../../core/toaster";
import styles from "./member_detail.module.scss";

export const MemberDetailTraining: React.FC<IPageLinkProps> = ({user, profile, refresh, setRefresh}) => {
    const role = useParamRole(user);
    const id = useParamId();
    const [member, setMember] = useState<IMember | null>();
    const [trainings, setTrainings] = useState<ITraining[]>([])
    const [newDateFrom, setNewDateFrom] = useState<Date | null>(new Date(Date.now()));
    const [newDateTo, setNewDateTo] = useState<Date | null>(null);
    const [newType, setNewType] = useState();
    const [newSubType, setNewSubType] = useState<string>();

    useEffect(() => {
        MemberService.get(role.orgId, id||0).then((m) => {
            setMember(m);
        });
    }, [id, role, refresh]);


    useEffect(() => {
        MemberService.trainings(role.orgId, id||0).then((m) => {
            setTrainings(m);
        });
    }, [id, role, refresh]);

    const trainingGroups = useMemo(() =>
            profile.trainingGroups
                .sort((a, b) => Number(a) - Number(b))
                .map(x => (x.trainings
                        .sort((a, b) => Number(a.sort) - Number(b.sort))
                        .map(y => ({...y, group: x.groupName}))
                ))
                .flat()
                .filter(x => x.editAtOrgLevel ? x.editAtOrgLevel.includes(role.orgId) : x.editAtOrgLevel == null)
                .map(x => ({
                    label: `${x.group} | ${x.name} | ${x.description}`,
                    value: x.id,
                    // Store original values for sorting
                    originalGroup: x.group || '',
                    originalName: x.name || '',
                    originalDescription: x.description || ''
                }))
                .sort((a, b) => {
                    // First sort by group
                    const groupCompare = (a.originalGroup || '').localeCompare(b.originalGroup || '');
                    if (groupCompare !== 0) return groupCompare;

                    // Then by name
                    const nameCompare = (a.originalName || '').localeCompare(b.originalName || '');
                    if (nameCompare !== 0) return nameCompare;

                    // Finally by description
                    return (a.originalDescription || '').localeCompare(b.originalDescription || '');
                })
                .map(({ label, value }) => ({ label, value })) // Remove the original fields from final output
        , [profile]);


    const assign = () => {
        if (newType) {
            toaster(async () => {
                await MemberService.assignTraining(
                    role.orgId,
                    id||0,
                    role.orgId,
                    newType,
                    DTS(newDateFrom as Date),
                    newDateTo ? DTS(newDateTo): undefined,
                    isGroup4Selected && newSubType ? { danType: newSubType as "P" | "V" | "A" | "E" } : undefined
                )
                setRefresh && setRefresh((refresh || 0) + 1);
            }, {success: 'Ausbildung hinzugefügt', failure: 'Fehler beim hinzufügen!'})
        }
    }

    const isGroup4Selected = useMemo(() => {
        const selectedTraining = profile.trainingGroups.flatMap(g => g.trainings).find(t => t.id === newType);
        return selectedTraining ? profile.trainingGroups.some(g => g.trainings.includes(selectedTraining) && g.groupId === 4) : false;
    }, [newType, profile.trainingGroups]);

    const subTypeOptions = useMemo(() => {
        return [
            { label: 'Prüfung', value: 'P' }, // früher: 1
            { label: 'Verleihung', value: 'V' }, // 2
            { label: 'Anerkennung', value: 'A' }, // 3
            { label: 'Ehrung', value: 'E' }, // 4
        ];
    }, []);

    const getLabelForValue = (value: string) => {
        const option = subTypeOptions.find(option => option.value === value);
        return option ? option.label : undefined;
    };

    if (member?.id) {
        return (
            <>
            {member?.isActive == true ?(
                <ItemNavGroup label={"Neue Ausbildung"}>
                    <Form>
                        <Item size={2} type={"full"} label={"Von"}  className={styles.formheader}>
                            <DateInput value={newDateFrom} onChange={setNewDateFrom}/>
                        </Item>
                        <Item size={2} type={"full"} label={"Bis"} className={styles.formheader}>
                            <DateInput value={newDateTo} onChange={setNewDateTo} minDate={newDateFrom as Date}/>
                        </Item>
                        <Item size={6} type={"full"} label={"Type"} className={styles.formheader}>
                            <Select freeHeight={true} value={newType} options={trainingGroups} onChange={setNewType}/>
                        </Item>
                        {isGroup4Selected ? (
                            <Item size={2} type={"full"} label={"DAN"} className={styles.formheader}>
                                <Select freeHeight={true} className={styles.dropDown} value={newSubType} options={subTypeOptions} onChange={setNewSubType}/>
                            </Item>
                        ) : ''}
                        <Button label={"Hinzufügen"} width={"full"} onClick={assign}/>
                    </Form>
                </ItemNavGroup>
            ):<ItemNavGroup label={"Ausbildungen"}>&nbsp;</ItemNavGroup>
            }
                <Row type="header">
                    <Item size={2} label="Von"/>
                    <Item size={2} label="Bis"/>
                    <Item size={5} label="Name"/>
                    <Item size={3} label="Prüfer"/>
                </Row>
                {trainings?.map((training) => (
                    <Row key={`training-${training.id}`} className={styles.row}>
                        <Item size={2}>
                            {FD(training.dateFrom)}
                        </Item>
                        <Item size={2}>
                            {FDX(training.dateTo) || '--'}
                        </Item>
                        <Item size={4}>
                            {training.name} {training.amgParams?.danType && `(${getLabelForValue(training.amgParams?.danType)})`}
                        </Item>
                        <Item size={4}>
                            {/*{training.controller}*/}
                            {training.pruefer?.['Vorsitz'] && (
                                <div>
                                    Vorsitz: {training.pruefer['Vorsitz']}
                                </div>
                            )}
                            {training.pruefer?.['1.Beisitz'] && (
                                <div>
                                    1. Beisitz: {training.pruefer['1.Beisitz']}
                                </div>
                            )}
                            {training.pruefer?.['2.Beisitz'] && (
                                <div>
                                    2. Beisitz: {training.pruefer['2.Beisitz']}
                                </div>
                            )}
                        </Item>
                    </Row>
                ))}
            </>
        );
    } else {
        return <p>Noch nicht verfǔgber</p>
    }
};