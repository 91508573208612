import React, { useState, useCallback } from "react";
import {Link, useSearchParams} from "react-router-dom";
import { KyuDetailEdit } from "./kyu_detail_edit";
import { KyuDetailNew } from "./kyu_detail_new";
import { KyuDetailCandidates } from "./kyu_detail_candidates";
import { KyuDetailApprove } from "./kyu_detail_approve";
import Kyu from "./kyu";
import styles from "../licences/licence.module.scss";
import { IPageLinkProps } from "../../page.props";
import {faAdd} from "@fortawesome/free-solid-svg-icons";
import {ItemNav} from "../../form/ItemNav";

interface ExtendedPageLinkProps extends IPageLinkProps {
    refresh?: number;
    setRefresh?: React.Dispatch<React.SetStateAction<number>>;
    triggerRefresh: () => void;
    role: {
        orgLevel: number;
        orgId: number;
        funkMgId: number;
    };
}

export const KyuDetail: React.FC<ExtendedPageLinkProps> = ({ role, ...props }) => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get('t');
    const [refreshCounter, setRefreshCounter] = useState(0);
    const [candidateToggled, setCandidateToggled] = useState(false);
    const triggerRefresh = useCallback(() => {
        console.log('triggerRefresh called');
        setRefreshCounter(prev => prev + 1);
        setCandidateToggled(prev => !prev);  // Toggle this state when refresh is triggered
    }, []);
    const childProps = {
        ...props,
        refresh: refreshCounter,
        setRefresh: setRefreshCounter,
        triggerRefresh,
        candidateToggled,
        setCandidateToggled
    };
    const gotoUrl = `/${role?.orgId}/${role?.funkMgId}/kyu`;
    return (
        <>
            {/* Render the appropriate component based on the 'page' parameter */}
            {page === 'edit' && <KyuDetailEdit {...childProps} />}
            {(page === 'new' || page === 'delete') && <KyuDetailNew {...childProps} />}
            {page === 'candidates' && <KyuDetailCandidates {...childProps} />}
            {page === 'approve' && <KyuDetailApprove {...childProps} />}
            {!page && (
                <div className={styles.headerempty}>
                    <p>KYU-Prüfung</p>
                    <p>Bitte wähle links eine KYU-Prüfung</p>
                    {role.orgLevel == 3 && (
                        <p>
                            oder erstelle <Link to={`${gotoUrl}/0?t=new`}> hier eine neue KYU-Prüfung.</Link>
                        </p>
                    )}
                </div>
            )}
        </>
    );
};

export default KyuDetail;