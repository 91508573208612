import React, {useEffect, useMemo, useState} from 'react';
import {IPageLinkProps} from "../../page.props";
import {useParamId, useParamRole} from "../../useParam";
import {IMember, IRole} from "../../../api/models/MemberModel";
import {MemberService} from "../../../api/member";
import {Button, DateInput, DTS, FD, FDS, FDX, FDXS, Form, Item, Select} from "../../form";
import {Row} from "../../form/row";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {toaster} from "../../../core/toaster";
import styles from "./member_detail.module.scss";
//import generalstyles from '../../../theme/editions/judoaustria/index.scss';
import { getOrgLevelFromId } from '../../../util/roles';
import classNames from "classnames";

export const MemberDetailRoles: React.FC<IPageLinkProps> = ({user, profile, refresh, setRefresh}) => {
    const role = useParamRole(user);
    const id = useParamId();
    const [member, setMember] = useState<IMember | null>();
    const [roles, setRoles] = useState<IRole[]>([]);
    const [rolesExpired, setRolesExpired] = useState<IRole[]>([]);

    const [newDateFrom, setNewDateFrom] = useState<Date|null>(new Date(Date.now()));
    const [newDateTo, setNewDateTo] = useState<Date | null>(null);
    const [terminateDate, setTerminateDate] = useState<Date | null>(null);
    const [newType, setNewType] = useState();
    const [newOrg, setNewOrg] = useState(0);
    const [changeOrg, setChangeOrg] = useState(0);

    useEffect(() => {
        MemberService.get(role.orgId, id||0).then((m) => {
            setMember(m);
        });
    }, [id, role, refresh]);


    useEffect(() => {
        MemberService.roles(role.orgId, id||0).then((r) => {
            setRoles(r);
        });
    }, [id, role, refresh]);

    useEffect(() => {
        MemberService.rolesExpired(role.orgId, id||0).then((r) => {
            setRolesExpired(r);
        });
    }, [id, rolesExpired, refresh]);

    useEffect(() => {
        setNewOrg(role.orgId)
    }, [role]);

    // const roleGroups =  useMemo(() => profile.roleGroups.sort((a,b) => Number(a) - Number(b)).map(x => ( x.roles.map(y => ({...y, group: x.groupName})))).flat()
    //         .filter(x => x.editWhitelist ? x.editWhitelist.includes(role.orgId) : x.editWhitelist == null)
    //         .map(x =>  ({
    //             label: `${x.group} | ${x.name}`,
    //             value: x.id
    //         }))
    //     , [profile, role]);
    const roleGroups = useMemo(() => {
        // Get the current organization's level from the selected org ID (newOrg)
        const currentOrgLevel = getOrgLevelFromId(newOrg);
        // console.log('##currentOrgLevel##', currentOrgLevel);
        // console.log('##newOrg##', newOrg);
        // console.log('##profile.roleGroups##', profile.roleGroups);

        const result = profile.roleGroups
            .sort((a, b) => Number(a) - Number(b))
            .map(x => x.roles.map(y => ({...y, group: x.groupName})))
            .flat()
            .filter(x => {
                // Check if the current user's org has permission to edit/assign this role
                const userOrgHasPermission = x.editWhitelist ?
                    x.editWhitelist.includes(role.orgId) :
                    x.editWhitelist == null;

                // Check if the selected organization is explicitly blacklisted
                const notBlacklisted = x.orgBlacklist ?
                    !x.orgBlacklist.includes(newOrg) :
                    true;

                // If orgWhitelist exists, selected org MUST be in it
                const whitelistOk = x.orgWhitelist ?
                    x.orgWhitelist.includes(newOrg) :
                    true;

                // console.log('##role##', x.name);
                // console.log('##userOrgHasPermission##', userOrgHasPermission);
                // console.log('##notBlacklisted##', notBlacklisted);
                // console.log('##whitelistOk##', whitelistOk);
                // console.log('##orgWhitelist##', x.orgWhitelist);

                return userOrgHasPermission && notBlacklisted && whitelistOk;
            })
            .map(x => ({
                label: `${x.group} | ${x.name}`,
                value: x.id
            }));

        // console.log('##final roleGroups##', result);
        return result;
    }, [profile, role, newOrg]);

    // console.log('##roleGroups##', roleGroups);

    const assign = () => {
        //if (meeting)
        //console.log(newType)
        if (newType) {
            toaster(async () => {
                await MemberService.assignRole(
                    role.orgId, id||0 , newOrg|| role.orgId, newType, DTS(newDateFrom as Date), newDateTo ? DTS(newDateTo): undefined
                )
                //console.log(refresh)
                setRefresh && setRefresh((refresh || 0) + 1);

                //console.log( refresh)
                return
            }, {success: 'Funktion hinzugefǔgt', failure: 'Fehler beim hinzufügen!'})
        }
    }

    const doChangeOrg = () => {
        toaster( async() => {
            await MemberService.switchOrg(role?.orgId || 0, member?.orgId || 0, changeOrg || 0, member?.id || 0,undefined )
            setRefresh && setRefresh((refresh || 0) + 1);
        })
    }

    const doTerminateMembership = () => {
        toaster( async() => {
            await MemberService.terminate(role?.orgId || 0, member?.orgId || 0, member?.id || 0, DTS(terminateDate as Date) );
            setRefresh && setRefresh((refresh || 0) + 1);
        });
    }

    const doTerminateMembershipDeceased = () => {
        toaster( async() => {
            await MemberService.terminate(role?.orgId || 0, member?.orgId || 0, member?.id || 0, DTS(terminateDate as Date), true );
            setRefresh && setRefresh((refresh || 0) + 1);
        });
    }

    const doReactivateMembership = () => {
        toaster( async() => {
            await MemberService.reactivate(role?.orgId || 0, member?.orgId || 0, member?.id || 0 );
            setRefresh && setRefresh((refresh || 0) + 1);
        });
    }
    const doAddMembership = () => {
        toaster( async() => {
            await MemberService.assignRoleSimple(role?.orgId || 0,  member?.id || 0, 'MEMBERSHIP');
            setRefresh && setRefresh((refresh || 0) + 1);
        });
    }
    console.log('role.org; ',role.orgId);
    //console.log('#profile.organizations#', profile.organizations);
    const filteredOrganizations = useMemo(() => {
        return profile.organizations
            .filter(org => role.orgId === 1 ? true : (org.parent === role.orgId || org.id === role.orgId))
            .sort((a, b) => {
                // First sort by parent
                if (a.orgLevel !== b.orgLevel) {
                    return a.orgLevel - b.orgLevel;
                }
                // Then sort by parent
                if (a.parent !== b.parent) {
                    return a.parent - b.parent;
                }
                // Finally sort by nameShort
                return a.nameShort.localeCompare(b.nameShort);
            })
            .map(x => ({
                label: x.nameShort,
                value: x.id
            }));
    }, [profile.organizations, role.orgId]);
    //console.log('##filteredOrganizations##', filteredOrganizations);

    const handleTerminateMembershipDeceased = () => {
        const isConfirmed = window.confirm("Sind Sie sicher, dass Sie dieses Mitglied als verstorben markieren möchten? Diese Aktion kann nicht rückgängig gemacht werden.");
        if (isConfirmed) {
            doTerminateMembershipDeceased();
        }
    };
    //console.log('##member##', member);

    if (member?.id) {
        return (
            <>
                {member?.verstorben !== 1 /*&& roleGroups.length > 0*/ ? (
                    <ItemNavGroup label={"Neue Funktion"}>
                        <Form>
                            {/*{role.orgId <= 10 ? (*/}
                                <Item size={12} type="full" label={"Organisation (ÖJV/LV/JV)"}>
                                    <Select value={newOrg} options={filteredOrganizations} onChange={setNewOrg}/>
                                </Item>
                            {/*) : ''}*/}
                            <Item size={2} label="Von">
                                <DateInput value={newDateFrom} onChange={setNewDateFrom}/>
                            </Item>
                            <Item size={2} label="Bis">
                                <DateInput value={newDateTo} onChange={setNewDateTo} minDate={newDateFrom as Date}/>
                            </Item>
                            <Item size={8} type={"full"} label="Type">
                                <Select value={newType} options={roleGroups} onChange={setNewType}/>
                            </Item>
                            <Button width={"full"} label={"Hinzufügen"} onClick={assign} />
                        </Form>
                    </ItemNavGroup>
                ) : ''}
                <ItemNavGroup label={"Rollen & Berechtigungen"} className={styles.spacerTop}>
                    <Row type="header">
                        <Item size={1} label="Von"/>
                        <Item size={1} label="Bis"/>
                        <Item size={3} label="Name"/>
                        <Item size={3} label="RollenGruppe"/>
                        <Item size={4} label="Org"/>
                    </Row>
                    {roles?.map((role) => (
                        <Row key={`training-${role.id}-${role.orgId}-${role.dateFrom}`}>
                            <Item size={1}>
                                {FDS(role.dateFrom)}
                            </Item>
                            <Item size={1}>
                                {FDXS(role.dateTo) || '--'}
                            </Item>
                            <Item size={3}>
                                {role.name}
                            </Item>
                            <Item size={3}>
                                {role.group ? role.group.String : 'N/A'}
                            </Item>
                            <Item size={4}>
                                {role.orgName}
                            </Item>
                        </Row>
                    ))}
                </ItemNavGroup>

                <ItemNavGroup label={"Vereinswechsel"} className={styles.spacerTop}>
                {(member?.id && role.orgLevel <= 2 && member.isActive) ? <Form>
                    <Item type="full" size={8}>
                            <Select value={changeOrg} onChange={setChangeOrg}
                                    options={filteredOrganizations} />
                        </Item>
                        <Item type="full" size={4} >
                            <Button width={"full"} label={"Wechseln"} onClick={() => doChangeOrg()} />
                        </Item>
                   </Form>: ''
                }
                </ItemNavGroup>
                <ItemNavGroup label={"Mitgliedschaft beenden"}  className={styles.spacerTop}>
                <Form>
                    {member?.verstorben !== 1 ? (
                        <>
                        {(member?.id && role.orgLevel <= 3 && member.isActive) ? (
                            <>
                                <Item type={'full'} size={4}>
                                    <DateInput value={terminateDate} onChange={setTerminateDate} minDate={new Date(Date.now())}/>
                                </Item>
                                <Item type="full" size={8} >
                                    <Button width={"full"} label={"Mitgliedschaft beenden"} onClick={() => doTerminateMembership()} disabled={terminateDate == null} />
                                </Item>
                            </>
                        ) : (
                            <Item type="full" size={12} >
                                <Button width={"full"} label={"Mitgliedschaft reaktivieren"} onClick={() => doAddMembership()}  />
                            </Item>
                        )}

                        <Item type="full" size={6} >
                            <Button
                                width={"full"}
                                label={"Mitglied verstorben"}
                                onClick={handleTerminateMembershipDeceased}
                                disabled={false}
                            />
                        </Item>
                        <Item type="full" size={6} >
                            <div>ACHTUNG!<br/> Mitglied wird aus ALLEN Funktionen entfernt!</div>
                        </Item>
                        </>
                    ): (<Item type="full" size={12} >
                        <h4>Mitglied ist im System als "verstorben" markiert.</h4>
                    </Item>)}
                </Form>
                </ItemNavGroup>
{/*                {(member?.id && member?.isActive) ? <Form>
                    <Item type={'full'} size={6}>
                        <DateInput value={terminateDate} onChange={setTerminateDate} minDate={new Date(Date.now())}/>
                    </Item>
                    <Item type="full" size={6} >
                        <Button width={"full"} label={"Mitgliedschaft beenden"} onClick={() => doTerminateMembership()} disabled={terminateDate == null} />
                    </Item>
                </Form>: ''}*/}
                <ItemNavGroup
                    label={"Historie"}
                    className={classNames({
                        [styles.history]: true,
                        [styles.spacerTop]: true
                    })}
                >
                    <Row type="header">
                        <Item size={1} label="Von"/>
                        <Item size={1} label="Bis"/>
                        <Item size={3} label="Name"/>
                        <Item size={3} label="RollenGruppe"/>
                        <Item size={4} label="Org"/>
                    </Row>
                    {rolesExpired?.map((role) => (
                        <Row key={`training-${role.id}-${role.orgId}-${role.dateFrom}`}>
                            <Item size={1}>
                                {FDS(role.dateFrom)}
                            </Item>
                            <Item size={1}>
                                {FDXS(role.dateTo) || '--'}
                            </Item>
                            <Item size={3}>
                                {role.name}
                            </Item>
                            <Item size={3}>
                                {role.group ? role.group.String : 'N/A'}
                            </Item>
                            <Item size={4}>
                                {role.orgName}
                            </Item>
                        </Row>
                    ))}
                </ItemNavGroup>

            </>
        );
    } else {
        return <p>Noch nicht verfǔgber</p>
    }
};