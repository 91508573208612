import React, {useEffect, useMemo, useState} from "react";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {BackButton, Button, DateInput, DTS, Form, Input, Item, Label, PDS, Segment, Select} from "../../form";
import {InputArea} from "../../form/InputArea";
import {SelectMulti} from "../../form/SelectMulti";
import {Upload} from "../../form/Upload";

import {addMonths} from "date-fns";
import {IFileUpload, IMeetingCandidateWeightClass, IOrgFunction} from "../../../api/models";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {toaster} from "../../../core/toaster";
import {MeetingService} from "../../../api/meeting";
import {IMeeting} from "../../../api/models";
import {IPageLinkProps} from "../../page.props";

import styles from './event_detail.module.scss';
import {tr} from "date-fns/locale";
import {MetaService, UtilService} from "../../../api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";

export function doStupidStringIntBoolParamsConverstion(value: any){
    if (value === '1') return true;
    if (value === '0') return false;
    if (value === '') return false;
    if (value === 1) return true;
    if (value === 0) return false;
    if (value === true) return true;
    if (value === false) return false;
    return false;
}

export function findInStrangeAgeClassesObject(ageClass: string, ageWeightClass: IMeetingCandidateWeightClass ): string[] {

     const res = ageWeightClass?.weightClass?.reduce((p, c) => {
         if (Object.keys(c).includes(ageClass)) {
             return c[ageClass]
         }
         return p;
     }, [] as string[])
    return res || [];
}

export function flattenMeetingAgeClasses(ageClassses : any) : string[] {
    /*
        m : ["U8M", "U12M", "U23M"]
        w: ["U18F"]
    */
    return (ageClassses['m'] || []).concat(ageClassses['w'])
}

export const EventDetailEdit: React.FC<IPageLinkProps> = ({user, profile, gotoUrl, refresh, setRefresh}) => {
    const params = useParams();
    const [eventType, setEventType] = useState<number>(1);
    const [openFrom, setOpenFrom] = useState<Date | null>(null);
    const [openTo, setOpenTo] = useState<Date | null>(null);
    const [from, setFrom] = useState<Date | null>(null);
    const [to, setTo] = useState<Date | null>(null);
    const [name, setName] = useState<string | null>(null);
    const [ort, setOrt] = useState<string>("");
    const [altersklasse, setAltersklasse] = useState<string[]>([]);
    const [meetingRoles, setMeetingRoles] = useState<number[]>([]);
    const [bundesweit, setBundesweit] = useState<boolean>(false)
    const [publish, setPublish] = useState(false);
    const [url, setUrl] = useState<string | null>(null);
    const [streamingUrl, setStreamingUrl] = useState<string | null>(null)
    const [text, setText] = useState<string | null>(null)
    const [preis, setPreis] = useState<string | null>(null);
    const [teilnahme, setTeilnahme] = useState<string | null>(null)
    const [meeting, setMeeting] = useState<IMeeting | null>(null)
    const [country, setCountry] = useState<string>("AUT");
    const [nationencheck, setNationencheck] = useState<boolean>(false);

    const navigate = useNavigate()

    const meetingId = Number(params['id'] || 0);
    const roleId = Number(params['role'] || 0);
    const role = user?.rolesOrg.map(x => x?.adminOrg || x?.trainerOrg).flat().find(x => x?.funkMgId === roleId) as IOrgFunction;

    const akOptions = useMemo(() => {
        // Define custom age category order
        const ageOrder = ['SEN', 'U23', 'U21', 'U18', 'U16', 'U14', 'U12', 'U10', 'U8'];

        return profile?.weightClasses
            .map(x => ({label: x.short, value: x.short}))
            .filter((x, i, a) => a.indexOf(a?.find(y => y?.value === x?.value) as {
                label: string,
                value: string
            }) === i)
            .sort((a, b) => {
                // Extract age category (e.g., "U8", "SEN", "U23")
                const categoryA = a.value.replace(/[MF]$/, ''); // Remove M/F suffix
                const categoryB = b.value.replace(/[MF]$/, ''); // Remove M/F suffix

                // Get index from custom order array
                const orderA = ageOrder.indexOf(categoryA);
                const orderB = ageOrder.indexOf(categoryB);

                // If categories are different, sort by custom order
                if (orderA !== orderB) {
                    return orderA - orderB;
                }

                // If categories are the same, sort M before F
                const genderA = a.value.slice(-1); // get last character (M or F)
                const genderB = b.value.slice(-1);
                return genderA.localeCompare(genderB);
            }) || [];
    }, [profile]);

    console.log('*** akOptions ***: ', akOptions)

    const [fileUploads, setFileUploads] = useState<IFileUpload[]>([]);
    //console.log('fileUploads: ', fileUploads)
    useEffect(() => {
        setEventType(meeting?.meetingtype_id || 1)
        setOpenFrom(PDS(meeting?.open_from || meeting?.param?.OPENFROM) )
        setOpenTo(PDS(meeting?.open_to || meeting?.param?.NENNSCHLUSS))
        setFrom(PDS(meeting?.startdate))
        setTo(PDS(meeting?.enddate))
        setName(meeting?.name || null)
        setOrt(meeting?.param?.ORT || '')
        setAltersklasse( (meeting?.ages_nennbar?.m || []).concat(meeting?.ages_nennbar?.w || []) || [])
        setMeetingRoles( meeting?.param?.MEETINGROLES_REQ || JSON.parse(meeting?.param?.MEETINGROLES || "[]") || [])
        setBundesweit(doStupidStringIntBoolParamsConverstion(meeting?.param?.BUNDESWEIT))
        setPublish(doStupidStringIntBoolParamsConverstion(meeting?.param?.PUBLISH_API))
        setUrl(meeting?.param?.URL || null)
        setStreamingUrl(meeting?.param?.STREAMING_URL || null)
        setText(meeting?.param?.TEXT || null)
        setPreis(meeting?.param?.PRICING || null)
        setTeilnahme(meeting?.param?.TEILNAHME || null)
        setCountry(meeting?.param?.COUNTRY_IOC || 'AUT')
        setNationencheck(doStupidStringIntBoolParamsConverstion(meeting?.param?.NATIONENCHECK))
        setFileUploads(meeting?.fileUploads || []);
    }, [meeting]);
    //console.log('fileUploads2: ', fileUploads)
    const meeting_fileUploads = meeting?.fileUploads || [];
    //console.log('meeting?.fileUploads: ', meeting_fileUploads)
    // useEffect(() => {
    //     const eventTypeObject = profile.meetingTypes.find(x => x.id === eventType)
    //     if (eventTypeObject) {
    //         setMeetingRoles(eventTypeObject.param.MEETINGROLES || []);
    //         setPublish(eventTypeObject.param.PUBLISH_API === 1 || false);
    //         setBundesweit(eventTypeObject.param.BUNDESWEIT === 1 || false);
    //         setNationencheck(eventTypeObject.param.NATIONENCHECK === 1 || false);
    //         //console.log(eventTypeObject)
    //     }
    // }, [profile, eventType]);

    //console.log('publish:',publish)

    useEffect(() => {
        if (role?.orgId && meetingId) {
            MeetingService.get(role.orgId, meetingId).then(setMeeting)
        }
    }, [role]);

    const validate = () => {
        return openFrom != null && openTo != null && from != null && to != null &&
            (name?.length || 0) > 0;
    }

    const create = async (data: React.MouseEvent<HTMLButtonElement>): Promise<boolean> => {
        if (validate()) {
            toaster(async () => {

                const m: IMeeting = {
                    id: meetingId || undefined,
                    meetingtype_id: eventType,
                    name: name as string,
                    open_from: DTS(openFrom as Date),
                    open_to: DTS(openTo as Date),
                    startdate: DTS(from as Date),
                    enddate: DTS(to as Date),
                    org_id: role.orgId,
                    param: {
                        ORT: ort as string,
                        MEETINGROLES_REQ: meetingRoles,
                        URL: url,
                        STREAMING_URL: streamingUrl,
                        TEXT: text,
                        PRICING: preis,
                        TEILNAHME: teilnahme,
                        BUNDESWEIT: bundesweit,
                        PUBLISH_API: publish,
                    },
                    ages_nennbar: {
                        m: altersklasse.filter(x => x.endsWith('M')),
                        w: altersklasse.filter(x => x.endsWith('F'))
                    },
                    mutter_id: 0, // create duplicates?
                    //bl_begeg_id: beg
                    fileUploads: [],
                } as unknown as IMeeting // todo fix
                //if (meeting)
                const newMeeting = m.id ? await MeetingService.update(role.orgId, m) : await MeetingService.create(role.orgId, m);
                navigate(`${gotoUrl}/${newMeeting}?t=edit`)
                setRefresh && setRefresh((refresh ||0)+1);

                return
            }, {success: 'Event erfolgreich angelegt', failure: 'Fehler beim Anlegen der Prüfung!'})
        }
        return false;
    }

    // const handleFileUpload = (newFile: any) => {
    //     if (meeting) {
    //         const updatedMeeting = {
    //             ...meeting,
    //             fileUploads: [...(meeting.fileUploads || []), newFile]
    //         };
    //         setMeeting(updatedMeeting);
    //     }
    // };
    const handleFileUpload = (newFile: IFileUpload) => {
        if (meeting) {
            const updatedMeeting = {
                ...meeting,
                fileUploads: [...(meeting.fileUploads || []), newFile]
            };

            setMeeting(updatedMeeting);
        }
    };


    const handleFileDelete = (fileToDelete: any) => {
        if (meeting) {
            toaster(async () => {
                await UtilService.DeleteFile(role.orgId,fileToDelete.name);
            }, {success: 'Event erfolgreich gelöscht', failure: 'Fehler beim Löschen der Datei!'})

            const updatedMeeting = {
                ...meeting,
                fileUploads: (meeting.fileUploads || []).filter(file => file !== fileToDelete)
            };
            setMeeting(updatedMeeting);
        }
    };

    return <>
        <BackButton label={'Zurück'}/>
        <ItemNavGroup label="Event bearbeiten">
            <Form>
                <Item type="full" label="Name" required={true}>
                    <Input
                        value={name || ''}
                        onChange={(v) => setName(v)}

                    />
                </Item>
                <Item type="full" label="Veranstaltungstyp" size={6} required={true}>
                    <Select value={eventType} onChange={setEventType}
                            options={profile?.meetingTypes.sort((a, b) => a.name.localeCompare(b.name)).map(x => ({
                                label: x.name,
                                value: x.id,
                            })) || []} />
                </Item>
                <Item type="full" label="Veranstalter (wird autom. erstellt)" size={6}>
                    <Input
                        value={Array.isArray(profile.organizations) ? profile.organizations.find(org => org.id === meeting?.org_id)?.nameShort : ''}
                        disabled={true}
                    />
                </Item>
                <Item type="full" label="Teilnehmer-Rollen" size={12} required={true}>
                    <SelectMulti onChange={setMeetingRoles} value={meetingRoles}
                                 options={(profile?.meetingRoles || [])
                                     .sort((a, b) => a.name.localeCompare(b.name))
                                     .map(x => ({label: x.name, value: x.id}))
                                 }/>
                </Item>


                <Item type="full" label="Anmeldung Von" size={6} required={true}>
                    <DateInput
                        value={openFrom}
                        onChange={setOpenFrom}
                        minDate={new Date()}
                        maxDate={from || addMonths(new Date(), 18)}
                    />
                </Item>
                <Item type="full" label="Anmeldung Bis" size={6} required={true}>
                    <DateInput
                        value={openTo}
                        onChange={setOpenTo}
                        disabled={!openFrom}
                        minDate={openFrom || undefined}
                        maxDate={from || addMonths(openFrom as Date, 18)}
                    />
                </Item>
                <Item type="full" label="Start" size={6} required={true}>
                    <DateInput
                        value={from}
                        onChange={setFrom}
                        disabled={!openTo}
                        minDate={openTo || undefined}
                        maxDate={addMonths(openTo as Date, 1)}
                    />
                </Item>
                <Item type="full" label="Ende" size={6} required={true}>
                    <DateInput
                        value={to}
                        onChange={setTo}
                        disabled={!from}
                        minDate={from || undefined}
                        maxDate={addMonths(from as Date, 1)}
                    />
                </Item>
                <Item type="full" label="Ort" size={12} required={true}>
                    <Input value={ort}
                           onChange={(v) => setOrt(v)}
                    />
                </Item>
                <Item type="full" label="Land IOC" size={12}>
                    <Select
                        value={country}
                        onChange={setCountry}
                        options={profile?.countries.map(x => ({label: x.name, value: x.IOC})) || []}
                    />
                </Item>
                <Item type="full" label="Nationencheck" size={6}>
                    <Segment value={nationencheck} onChange={setNationencheck}
                             options={[{label: "Nein", value: false}, {label: "Ja", value: true}]}/>
                </Item>
                <Item type="full" label="Bundesweit" size={6}>
                    <Segment value={bundesweit} onChange={setBundesweit}
                             options={[{label: "Nein", value: false}, {label: "Ja", value: true}]}/>
                </Item>
                <Item type="full" label="Nennbare Altersklassen" size={12}>
                    <SelectMulti onChange={setAltersklasse} value={altersklasse} options={akOptions||[]}/>
                </Item>
                <Item type="full" label="Url" size={12}>
                    <Input
                        value={url || ''}
                        onChange={(v) => setUrl(v)}
                    />
                </Item>
                <Item type="full" label="Streaming Url" size={12}>
                    <Input
                        value={streamingUrl || ''}
                        onChange={(v) => setStreamingUrl(v)}
                    />
                </Item>
                {/*<Item type="full" label="Bundesliga">
                    <Select onChange={() => null}
                            options={[{label: 'tbd', value: '3'}]}/>
                </Item>*/}

                <Item type="full" label="Anmerkungen">
                    <InputArea
                        value={text || ''}
                        onChange={(v) => setText(v)}
                        rows={5}
                    />
                </Item>
                {/*<Item type="full" label="Teilnahme" size={6}>*/}
                {/*    <Input*/}
                {/*        value={teilnahme || ''}*/}
                {/*        onChange={(v) => setTeilnahme(v)}*/}
                {/*    />*/}
                {/*</Item>*/}
                <Item type="full" label="Preis" size={6}>
                    <Input
                        value={preis || ''}
                        onChange={(v) => setPreis(v)}
                    />
                </Item>
                <Item type="full" label="Sofort Publizieren?" size={6}>
                    <Segment value={publish} onChange={setPublish}
                             options={[{label: "Nein", value: false}, {label: "Ja", value: true}]}/>
                </Item>
                {meetingId &&
                <div className={styles.uploader} >
                    <Item type="full" label="Anhänge" size={12}>
                        <ul>
                            {meeting_fileUploads.map((file, index) => (
                                <li key={index}>
                                        {/*<FontAwesomeIcon icon={faTrash} />*/}
                                    <Label>
                                        <a href={MetaService.resolveCompetitionFileUrlAWS( file.name)}
                                           target="_blank" download>{file.original}</a>
                                    </Label>
                                    <Button label={'Löschen'} onClick={()=>handleFileDelete(file)} className={styles.delete} ></Button>
                                </li>
                            ))}
                        </ul>
                        <Upload
                            orgId={role?.orgId}
                            purpose="meeting"
                            purposeId={meetingId}
                            onUploadComplete={(uploadedFile) => handleFileUpload(uploadedFile)}
                        />
                    </Item>
                </div>
            }
            </Form>
        </ItemNavGroup>
        <Button label="Speichern" className={styles.save} onClick={create} disabled={!validate()}/>
    </>
}
