export enum EKyuExamStatus {
    created = 'ERSTELLT',
    submitted = "BEANTRAGT",
    completed = 'ABGESCHLOSSEN',
    approved = 'FREIGEGEBEN',
    canceled = "ABGESAGT"
}

export interface IKyuExam {
    id: number;
    date: string;
    examChairMain: string;
    examChair1: string;
    examChair2: string;
    userId: number;
    editor?:string
    time: string;
    orgId: number;
    org: string;
    // completed: number;
    examChairMainId: any;
    examChair1Id: any;
    examChair2Id: any;
    name: any;
    items?: number;
    description: any;
    judopass: boolean;
    createdAt: string;
    updatedAt: string;
    // reservation: number;
    status?: EKyuExamStatus;
}

export interface IKyuChairMember {
    mgId: number;
    officialName: string;
    birthDate: string; // 'time.Time' in Go typically converts to 'string' in TypeScript when using as datetime in ISO format.
    age: number;
    officialFunction: string;
    officialImage: string;
    email?: string;
    judobaseId?: string;
    sort: number;
    funcGroup: string;
    homeBase?: string;
    phone?: string;
    judoCard?: string;
}


export interface IKyuCandidate {
    mgId: number;
    surname: string;
    firstname: string;
    birthdate: string;
    uuid: string;
    trainingBelt: string;
    traindingGrade: string;
    trainingSort: number;
    lastKyuDate: string;
    judocard?: number;
    beltsvg: string;
    assignedMgId?: number;
    nextTrainingId?: number;
    nextName?: string;
    deprecatedAlreadyAssigned?: number;
    nextBeltSvg?: string;
    outError?: {
       errcode: string;
       message: string;
    }[];
    ordersystemId?: number;
}