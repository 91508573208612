import React, {useEffect, useMemo, useState} from 'react';
import {IAdminOrganization} from "../../../api/models";
import {Loading} from "../../foundation/Loading";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {useParamId, useParamRole} from "../../useParam";
import {toaster} from "../../../core/toaster";
import {OrganizationService} from "../../../api/organization";
import {ItemNav} from "../../form/ItemNav";
import {faBuilding, faPlus} from "@fortawesome/free-solid-svg-icons";
import {IPageLinkProps} from "../../page.props";
import {Input, Item} from "../../form";
import styles from "../member/member.module.scss";


const Organization: React.FC<IPageLinkProps> = ({user, gotoUrl, profile, refresh}) => {
    const [loading, setLoading] = useState(true);
    const [organizations, setOrganizations] = useState<IAdminOrganization[]>([]);
    const [search, setSearch] = useState<string>('');

    const id = useParamId();
    const role = useParamRole(user);

    useEffect(() => {
        if (role) {
            //console.log('Organization component refreshing, refresh count:', refresh);
            (async () => {
                try {
                    const orgs = await OrganizationService.all(role?.orgId);
                    setOrganizations([...orgs]);
                } catch (error) {
                    console.error('Error fetching organizations:', error);
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [role, refresh]);

    const filter = useMemo(() => {
        const filteredOrganizations = search.length > 0
            ? organizations.filter(x =>
                (x.short?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    x.name?.toLowerCase()?.includes(search?.toLowerCase())))
            : organizations.filter(x => x.parent === role.orgId || x.id === role.orgId);

        const sorted = filteredOrganizations.sort((a, b) => a.name.localeCompare(b.name));
        return sorted;
    }, [organizations, role.orgId, search]);

    return (
        <>
            {loading || !role ? <Loading/> : <>
                {role?.orgId == 1 && <ItemNav icon={faPlus} label={"Neue Organisation erstellen"}
                                              href={`${gotoUrl}/?t=new`}/>}
                <ItemNavGroup label={role?.orgName}>
                    <Item type="full" className={styles.search}>
                        <Input placeholder={"Suche Vereinsname"} onChange={setSearch} value={search}
                               className={styles.name}/>
                    </Item>
                    {filter.slice(0, 200).map(o => (
                        <ItemNav
                            key={`org${o.id}`}
                            icon={faBuilding}
                            label={o.short}
                            href={`${gotoUrl}/${o.id}`}
                            selected={id === o.id}
                            className={styles.clubHover}
                        />
                    ))}
                    <div>_</div>

                </ItemNavGroup>
                {filter.length < organizations.length && filter.length > 200 && search.length > 0 && (<Item>Es werden nur die ersten 20 Ergebnise angezeigt</Item>)}
            </>}
        </>
    );
};

export default Organization;