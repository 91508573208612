import {IOrgFunction, IUserModel} from "../api/models";


export function GroupAdminRoles(user: IUserModel) {
    return  FlattenAdminRoles(user)
        .reduce((p,c) => {
                if (p[c.orgName]) {
                    p[c.orgName].push(c);
                } else {
                    p[c.orgName]=[c];
                }
                return p;
            }
            , {} as {[idx: string]: IOrgFunction[]});
}

export function GroupTrainerRoles(user: IUserModel) {
    return  FlattenTrainerRoles(user)
        .reduce((p,c) => {
                if (p[c.orgName]) {
                    p[c.orgName].push(c);
                } else {
                    p[c.orgName]=[c];
                }
                return p;
            }
            , {} as {[idx: string]: IOrgFunction[]});
}
export function FlattenAdminRoles(user: IUserModel) {
    return user ? user.rolesOrg.map(x => x.adminOrg || []).flat().filter(x => x != null) as IOrgFunction[] : [];
}
export function FlattenTrainerRoles(user: IUserModel) {
    return user ? user.rolesOrg.map(x => x.trainerOrg || []).flat().filter(x => x != null) as IOrgFunction[] : [];
}

export function getOrgLevelFromId(orgId: number): number {
    if (orgId === 1) return 1;
    if (orgId >= 2 && orgId <= 10) return 2;
    return 3;
}