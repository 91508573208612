import React, {useState} from 'react';
import {withDesktop} from "../../api/withDesktop";
import {IPageProps} from "../../components/page.props";
import {Layout} from "../../components/foundation/layout";
import Organization from "../../components/admin/organization/organization";
import OrganizationDetail from "../../components/admin/organization/organization_detail";
import {useParamRole} from "../../components/useParam";


export const DesktopOrganizationsPage: React.FC<IPageProps> = (props) => {
    const role = useParamRole(props.user);
    const gotoUrl = `/${role?.orgId}/${role?.funkMgId}/organizations/`;
    const [refresh, setRefresh] = useState(0);
    return (
        <Layout list={
            <Organization {...props} gotoUrl={gotoUrl} refresh={refresh} setRefresh={setRefresh}  />
        } page = {
            <OrganizationDetail {...props} gotoUrl={gotoUrl} refresh={refresh} setRefresh={setRefresh} />
        }
        />
    );
};

export default withDesktop(DesktopOrganizationsPage)