import React, {useState, useCallback, useEffect} from 'react';
import {withDesktop} from "../../api/withDesktop";
import {Layout} from "../../components/foundation/layout";
import Kyu from "../../components/admin/kyu/kyu";
import {IPageProps} from "../../components/page.props";
import {useParamRole} from "../../components/useParam";
import KyuDetail from "../../components/admin/kyu/kyu_detail";
import printStyles from '../../components/admin/kyu/print.module.scss';

export const DesktopKyuPage: React.FC<IPageProps> = (props) => {
    const role = useParamRole(props.user);
    const [refresh, setRefresh] = useState<number>(0);

    const gotoUrl = `/${role?.orgId}/${role?.funkMgId}/kyu`;

    const triggerRefresh = useCallback(() => {
        console.log('DesktopKyuPage: triggerRefresh called');
        setRefresh(prev => {
            const newValue = prev + 1;
            console.log('DesktopKyuPage: Setting new refresh value:', newValue);
            return newValue;
        });
    }, []);

    useEffect(() => {
        console.log('DesktopKyuPage: refresh state changed to', refresh);
    }, [refresh]);

    return (
        <Layout
            listClassName={printStyles['hide-for-print']}
            list={

                    <Kyu
                        {...props}
                        gotoUrl={gotoUrl}
                        refresh={refresh}
                        triggerRefresh={triggerRefresh}
                    />

            }
            page={
                <KyuDetail
                    {...props}
                    gotoUrl={gotoUrl}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    triggerRefresh={triggerRefresh}
                    role={role}
                />
            }
        />
    );
};

export default withDesktop(DesktopKyuPage);