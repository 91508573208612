import React, { useEffect, useState } from "react";
import { IPageLinkProps } from "../../page.props";
import { TabLayout } from "../../foundation/tab_layout";
import { faAward, faList, faPerson } from "@fortawesome/free-solid-svg-icons";
import { MemberDetailTraining } from "./member_detail_training";
import { MemberDetailRoles } from "./member_detail_roles";
import { MemberDetailGeneral } from "./member_detail_general";
import { MemberService } from "../../../api/member";
import { useParamId, useParamRole } from "../../useParam";
import { IMember } from "../../../api/models/MemberModel";

const MemberDetail: React.FC<IPageLinkProps> = ({user, profile, gotoUrl, refresh, setRefresh}) => {
    const role = useParamRole(user);
    const id = useParamId();
    const [member, setMember] = useState<IMember | null>(null);
    console.log('#member.id-0', member?.id);
    useEffect(() => {
        if (id !== null) {
            if (id != 0) {
                MemberService.get(role.orgId, id).then((m) => {
                    setMember(m);
                });
            } else {
                setMember({ id: 0 } as IMember);  // Set member with id 0 for new member
            }
        }
    }, [id, role, refresh]);

    console.log('#member.id-1', member?.id);

    return (
        <>
            <TabLayout
                items={[
                    {
                        label: 'Stamm\u00ADdaten',
                        icon: faPerson,
                        component: <MemberDetailGeneral gotoUrl={gotoUrl} user={user} profile={profile} refresh={refresh} setRefresh={setRefresh} />,
                    },
                    ...(member?.id !== undefined && member.id != 0
                        ? [
                            {
                                label: 'Aus\u00ADbil\u00ADdungen',
                                icon: faAward,
                                component: <MemberDetailTraining gotoUrl={gotoUrl} user={user} profile={profile} refresh={refresh} setRefresh={setRefresh} />,
                            },
                            {
                                label: 'Funk\u00ADtionen & Lizenzen',
                                icon: faList,
                                component: <MemberDetailRoles gotoUrl={gotoUrl} user={user} profile={profile} refresh={refresh} setRefresh={setRefresh} />,
                            },
                        ]
                        : []),
                ]}
            />
        </>
    );
};

export default MemberDetail;