import React, {useEffect, useState} from "react";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {ItemNav} from "../../form/ItemNav";
import {FD, Item} from "../../form";
import {faAdd} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import {useParamId, useParamRole} from "../../useParam";
import {ELicenceStatus, ILicenceOrder} from "../../../api/models";
import {IPageLinkProps} from "../../page.props";

import styles from './licence.module.scss';
import {LicenceService} from "../../../api/licence";

const Licence: React.FC<IPageLinkProps> = ({user, profile, gotoUrl, refresh}) => {
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState<ILicenceOrder[]>([]);

    const id = useParamId();
    const role = useParamRole(user);

    useEffect(() => {
        setLoading(true);
        if (role != null) {
            LicenceService.all(role.orgId).then((o) => {
                setOrders(o);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [user, refresh, role]);

    const translateStatus = (status: ELicenceStatus, kyuExamId: number | null | undefined) => {
        switch (status) {
            case "canceled":
                return "Storniert";
            case "created":
                return "Entwurf";
            case "submitted":
                return kyuExamId !== null ? "KYU-Bestellung" : "Bestellt";
            case "approved":
                return "Erledigt";
            default:
                return "";
        }
    }

    console.log('orders:',orders);

    return <>
        <ItemNavGroup label={role?.orgName} className={styles.leftmenue}>
            <ItemNav
                align="center"
                label="Neue JUDOCARDs bestellen"
                icon={faAdd}
                href={`${gotoUrl}/?t=new`}
            />
            {orders?.length > 0 ? orders.map(e => (
                <ItemNav
                    key={`order-${e.id}`}
                    href={`${gotoUrl}/${e.id}?t=candidates`}
                    className={classNames(e.status === 'submitted' && styles.submitted, e.status === 'approved' && styles.approved)}
                    selected={id === e.id}
                >
                    <div className={styles.entry}>
                        <div className={styles.date}>
                            {FD(e.created)}
                            <div className={styles.nr}>Order-Nr. {e.id}</div> <div className={styles.org}>{e.orgName}</div>
                        </div>
                        <div className={styles.name}>
                            <div className={styles.info}>
                                <div className={classNames(styles.status, {
                                    [styles.submitted]: e.status === 'submitted' && !e.kyuExamId,
                                    [styles['submitted-by-kyu']]: e.status === 'submitted' && e.kyuExamId,
                                    [styles.approved]: e.status === 'approved'
                                })}>
                                    {translateStatus(e.status, e.kyuExamId)}
                                </div>
                                <div
                                    className={styles.kyupruefung}>{e.kyuExamId ? 'KYU: ' + e.kyuExamId : null}
                                </div>
                                {/*e.kyuExamId !== null ? styles.kyupruefungsubmitted :*/}
                            </div>
                            <div className={styles.judocardGroup}>
                                <div className={styles.judocard}>{e?.years[0]}</div>
                                <div className={styles.amount}>{e.items} Stück</div>
                            </div>
                        </div>
                    </div>
                </ItemNav>
            )) : <Item type="list" label="Keine Bestellungen verfügbar"/>}
        </ItemNavGroup>
    </>
}

export default Licence;
